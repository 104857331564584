import moment from "moment";
import React from "react";
import PrimaryButton from "../../components/buttons/button";
import { getPrice } from "../../utils/commonFunctions";

const ActivePlans = ({ plans, activePlan, setCancelPlan }) => {
    return (
        <>
            {/* {plans?.activePlan?.length > 0 &&
                activePlan?.map((item) => (
                    <> */}
            <p className="text-lg md:text-lg font-semibold heading-color mt-0 mb-3">Active Plan</p>
            <div className="shadow-3 border-round-xl overflow-hidden mb-4">
                <div className="active-card-header p-3 bg-main flex align-items-center justify-content-between">
                    <div className="">
                        <p className="text-xl m-0 nunito font-bold text-white">{activePlan?.planInfo?.name}</p>
                        <small className="text-white nunito">
                            {activePlan?.status === "CANCELLED" ? "Your current plan will be active till - " : "Next billing date - "}
                            <span className="font-bold">{moment(activePlan?.subscriptionExpireOn).format("DD/MM/YYYY")}</span>
                        </small>
                    </div>
                    <div className="">
                        <h2 className="text-white m-0 font-semibold text-right">{getPrice(activePlan?.planInfo)}</h2>
                        {activePlan?.isTrial ?
                            <small className="text-white nunito">
                                <span className="font-bold">({moment(activePlan?.startAt).diff(new Date(), "days")} days trial left)</span>
                            </small>
                            : null
                        }
                    </div>
                </div>
                <div className="p-3">
                    <ul className="list-none p-0">
                        {activePlan?.planInfo?.features.map((item) => (
                            <li className="font-medium mb-3 md:mb-5 flex gap-3 text-sm md:text-base nunitoe">
                                <i className="pi pi-check text-main"></i>
                                {item}
                            </li>
                        ))}
                    </ul>
                    <div className="flex justify-content-between">
                        <div className={`flex align-items-end ${activePlan?.status === 'active' ? "text-green-500" : activePlan?.status === 'cancelled' ? "p-error" : "text-yellow-700"}`}>{activePlan?.isTrial ? 'In Trial' : activePlan?.status} {activePlan?.status === 'created' ? <span className="text-xs ml-2"> (Please wait)</span> : ''}</div>
                        {activePlan?.status !== "CANCELLED" && (
                            <PrimaryButton disable={activePlan?.status === 'created'} label="Cancel Plan" btnclass="btn-outline" onClick={() => setCancelPlan(activePlan._id)} />
                        )}
                        {/* {item?.status === "CANCELLED" && <div className="p-error">Cancelled</div>} */}
                    </div>
                </div>
            </div >
        </>
        // ))
        // }
        // </>
    );
};

export default ActivePlans;

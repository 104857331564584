import React, { useState } from "react";
import { CustomBackground } from "../../components/background/CustomBackground";
import backgrooundImg from "../../assets/images/beautiful-young-woman-doing-yoga-street 1.webp";
import LogoImg from "../../assets/images/logo.png";
import CustomInput from "../../components/input/allinput";
import PrimaryButton from "../../components/buttons/button";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../layout/footer";
import { LoginCard } from "../../components/cards/card";
import { Topbar } from "../../layout/topbar";
import formValidation from "../../utils/validation";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { sendEmailAction } from "../../store/thunks/userThunk";
var base64 = require("base-64");

export default function ForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        email: "",
    });
    var encodedEmail = base64.encode(data.email);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSendOtp = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                sendEmailAction(data.email, () => {
                    navigate(`/otp-verification/${encodedEmail}`);
                })
            );
        }
    };
    return (
        <>
            <Topbar />
            <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5" bgImg={backgrooundImg}>
                <LoginCard>
                    <Link to="https://www.namameditation.com/">
                        <img src={LogoImg} alt="" style={{ width: "140px" }} />
                    </Link>
                    <h1 className="md:text-center font-semibold heading-size text-main">Forgot Password?</h1>
                    <p className="subheading-color text-xl nunito">Don't worry, it happens. Take a deep breath and enter the email linked with your account.</p>
                    <CustomInput label="Email Address" extraClassName="w-full p-0" placeholder="" data={data} onChange={handleChange} name="email" />
                    <PrimaryButton label="Send OTP" btnclass=" mt-3 bg-main w-full" onClick={handleSendOtp} />

                    <p>
                        Remember Password?
                        <Link to="/signin" className="heading-color ml-1 font-semibold no-underline">
                            Sign In
                        </Link>
                    </p>
                </LoginCard>
                <Footer />
            </CustomBackground>
        </>
    );
}

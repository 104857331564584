import React from "react";

export const CustomBackground = ({ bgImg, children, className }) => {
    return (
        <div
            className={className}
            style={{
                background: `url('${bgImg}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: "calc(100vh - 91.64px)",
            }}
        >
            {children}
            <h3 className="text-sm text-white font-normal text-center">
                <span>Copyright © 2023- 2024 Incca Projects LLP. All rights reserved.</span>
                <br />
                <span>301 Hermitage, Sector 28 Gurgaon</span>
            </h3>
        </div>
    );
};

import { HashRouter, Route, Routes } from "react-router-dom";
import "./styles.scss";
import Plan from "./views/plan";
import SignIn from "./pages/login";
import ForgotPassword from "./pages/forget-password";
import ResetPassword from "./pages/reset-password";
import PasswordChanged from "./pages/password-changed";
import OtpVerification from "./pages/otp-verification";
import Profile from "./admin/profile";
import Loader from "./utils/Loader";
import ToastContainer from "./utils/ToastContainer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ProtectedRoutes } from "./routes/ProtectedRoutes";

function App() {
    return (
        <div className="App">
            <GoogleOAuthProvider clientId="1059145812869-qscv3q476c0gbtibpadgcgfqufr06l70.apps.googleusercontent.com">
                <HashRouter>
                    <Loader />

                    <Routes>
                        <Route path="/" exact={true} element={<Plan />} />
                        <Route path="/signin" exact={true} element={<SignIn />} />
                        <Route path="/forgot-password" exact={true} element={<ForgotPassword />} />
                        <Route path="/otp-verification/:encodedEmail" exact={true} element={<OtpVerification />} />
                        <Route path="/reset-password/:encodedEmail/:otpCode" exact={true} element={<ResetPassword />} />
                        <Route path="/password-changed" exact={true} element={<PasswordChanged />} />
                        <Route path="/profile" exact={true} element={<Profile />} />
                    </Routes>
                    <ProtectedRoutes />
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </HashRouter>
            </GoogleOAuthProvider>
        </div>
    );
}

export default App;

import React, { useState } from "react";
import { CustomBackground } from "../../components/background/CustomBackground";
import backgrooundImg from "../../assets/images/beautiful-young-woman-doing-yoga-street 1.webp";
import LogoImg from "../../assets/images/logo.png";
import PrimaryButton from "../../components/buttons/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../layout/footer";
import { ResendOTP } from "otp-input-react";
import { LoginCard } from "../../components/cards/card";
import { Topbar } from "../../layout/topbar";
import { CustomOtpInput } from "../../components/input/allinput";
import formValidation from "../../utils/validation";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { sendEmailAction } from "../../store/thunks/userThunk";
var base64 = require("base-64");

export default function OtpVerification() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        otp: "",
    });
    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const { encodedEmail } = useParams();
    var encodedOTP = base64.encode(data.otp);
    const email = base64.decode(encodedEmail);

    const handleVerify = () => {
        if (showFormErrors(data, setData)) {
            navigate(`/reset-password/${encodedEmail}/${encodedOTP}`);
        }
    };
    const renderButton = (buttonProps) => {
        return (
            <p className="bottom-link text-center heading-color font-normal col-12 ">
                Didn't Receive Code?
                <button className="  border-none bg-transparent  font-bold" {...buttonProps}>
                    {"Resend"}
                </button>
            </p>
        );
    };
    const renderTime = (remainingTime) => {
        return (
            <div className="col-12 md:col-7 lg:col-7 flex flex-column w-full mx-0 px-0">
                <p className="ml-3 flex font-medium justify-content-end align-items-end m-0 ">00:{remainingTime} </p>
                <PrimaryButton btnclass="mt-3 bg-main w-full" label={"Verify"} onClick={handleVerify} />
            </div>
        );
    };

    const handleResendOtp = () => {
        dispatch(
            sendEmailAction(email, () => {
                navigate(`/otp-verification/${encodedEmail}`);
            })
        );
    };

    return (
        <>
            <Topbar />
            <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5" bgImg={backgrooundImg}>
                <LoginCard>
                    <Link to="https://www.namameditation.com/">
                        <img src={LogoImg} alt="" style={{ width: "140px" }} />
                    </Link>
                    <h1 className="md:text-center font-semibold heading-size text-main">OTP Verification</h1>
                    <p className="subheading-color text-xl nunito">Enter the verification code we’ve just sent on your email address.</p>
                    <CustomOtpInput data={data} onChange={handleChange} name="otp" />
                    <div className="text-right mt-2">
                        <ResendOTP onResendClick={handleResendOtp} renderButton={renderButton} renderTime={renderTime} className="flex-column mt-4" />
                    </div>
                </LoginCard>
                <Footer />
            </CustomBackground>
        </>
    );
}

import moment from "moment";
import React from "react";
import CustomDialog from "../../components/Dialog/Dialog";

const UpgradeDialog = ({ upgradePlanOpen, setUpgradePlanModelOpen, activePlan, handleUpgradePlan, upcomingPlan }) => {
    return (
        <>
            <CustomDialog
                title="Upgrade Plan"
                contentClassName="pb-2"
                visible={upgradePlanOpen}
                onSave={handleUpgradePlan}
                onCancel={() => setUpgradePlanModelOpen(false)}
                label="Upgrade"
            >
                <p className=" mt-0 text-sm font-normal text-dark-gray ">
                    Your current pan will be active till {moment(activePlan?.[0]?.subscriptionExpireOn).format("DD/MM/YYYY")}. Next to the new plan Starts on
                    &nbsp;
                    {upcomingPlan?.length > 0
                        ? moment(upcomingPlan?.[upcomingPlan?.length - 1]?.subscriptionExpireOn).format("DD/MM/YYYY")
                        : moment(activePlan?.[0]?.subscriptionExpireOn).format("DD/MM/YYYY")}
                </p>
            </CustomDialog>
        </>
    );
};

export default UpgradeDialog;

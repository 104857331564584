import React from 'react'
import DashboardLayout from '../../dashboardlayout';
import ProfileImg from "../../assets/svg/Personal settings-cuate 1.png";

export default function Profile() {
  return (
    <DashboardLayout heading="Profile">
      <div className="w-full flex flex-column md:h-30rem align-items-center justify-content-center">
        <img src={ProfileImg} alt='' style={{width:"355px"}} />
        <p className='heading-color font-semibold'>Please update your profile on your nama app.</p>
      </div>
    </DashboardLayout>
  )
}

import constants from "../constants";
import { entries, notEqual, values } from "./javascript";
import formValidation from "./validation";

const showFormErrors = (data, setData, ignore) => {
    let formErrors = {};
    entries(data).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...formValidation(key, value, data, ignore),
        };
    });
    ignore?.forEach((name) => {
        if (formErrors[name]) {
            formErrors[name] = "";
        }
    });
    setData({ ...data, formErrors });
    return !values(formErrors).some((v) => notEqual(v, ""));
};
const getImageUrl = (image) => {
    if (image.includes("http")) {
        return image;
    } else {
        return constants.baseUrl + image;
    }
};

function getTimezone() {
    const date = new Date();
    const options = { timeZoneName: "long" };
    const timezone = new Intl.DateTimeFormat("en", options).formatToParts(date).find((part) => part.type === "timeZoneName").value;
    let zone = "US";
    if (timezone.includes("India")) {
        zone = "IN";
    }
    return zone;
}

const getPrice = (obj) => {
    let zone = getTimezone();
    let price = "$" + obj?.dollar;
    if (zone === "IN") {
        price = "₹" + obj?.price;
    }

    return price;
};

export { showFormErrors, getImageUrl, getTimezone, getPrice };

import React from "react";
import Container from "../components/container/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoImg from "../assets/images/logo.png";
import PrimaryButton from "../components/buttons/button";

export const Topbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className="header py-4">
            <Container containerClass="flex justify-content-between align-items-center">
                <Link to="https://www.namameditation.com/">
                    <img src={LogoImg} alt="" style={{ width: "110px" }} />
                </Link>
                <div className="flex gap-2">
                    <PrimaryButton
                        label="Sign In"
                        btnclass={` ${location.pathname === "/signin" ? "bg-main" : "btn-outline"}`}
                        onClick={() => navigate("/signin")}
                    />
                    {/* <PrimaryButton label="Sign Up" btnclass={` ${location.pathname === "/" ? "bg-main" : "btn-outline"}`} onClick={() => navigate("/")} /> */}
                </div>
            </Container>
        </div>
    );
};

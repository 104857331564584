import React from "react";
import { Dialog } from "primereact/dialog";
import PrimaryButton from "../buttons/button";

export default function CustomDialog({ title, visible, position, width = "60%", onSave, loading, onCancel, children, label, contentClassName }) {
    const footerContent = onSave ? <PrimaryButton label={label} btnclass="w-full bg-main" loading={loading} onClick={onSave} /> : null;

    return (
        <Dialog
            modal
            header={title}
            headerClassName="justify-content-start"
            visible={visible}
            style={{ width }}
            onHide={onCancel}
            footer={onSave || onCancel ? footerContent : ""}
            draggable={false}
            resizable={false}
            contentClassName={contentClassName}
            position={position}
        >
            {children}
        </Dialog>
    );
}

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    plans: {},
    payment: {},
};

export const planSlice = createSlice({
    name: "plans",
    initialState,
    reducers: {
        setUserPlans: (state, action) => {
            state.plans = action.payload;
        },
        setUserPlansPayment: (state, action) => {
            state.payment = action.payload;
        },
    },
});
export const { setUserPlans, setUserPlansPayment } = planSlice.actions;
export default planSlice.reducer;

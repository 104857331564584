import React from "react";

export default function InputLayout({
  labelClassName,
  label,
  name,
  required,
  col,
  extraClassName,
  errorMessage,
  data,
  colsize,
  children,
}) {
  col = parseInt(col);
  // if (col > 12) {
  //     col = 12;
  // }

  console.log(colsize);
  return (
    <div
      className={`py-0 text-left mb-3 ${
        colsize === "true" ? "col-12" : colsize
      } relative ${`md:col-${col} lg:col-${col}"`} ${extraClassName}`}
    >
      {label ? (
        <label
          className={`heading-color font-medium ${labelClassName}`}
          htmlFor={name}
        >
          {label}&nbsp;
          {required ? <span className="p-error">*</span> : ""}
        </label>
      ) : null}
      {children}

      <div className="text-left">
        {errorMessage || data?.formErrors?.[name] ? (
          <small className="p-error">
            {errorMessage || data?.formErrors?.[name]}
          </small>
        ) : null}
      </div>
    </div>
  );
}

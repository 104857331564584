import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="text-center mt-4 footer">
            <a href="https://www.namameditation.com/contact" target="_blank" className="text-white no-underline font-bold text-xl nunito">
                Contact Us
            </a>
            <p className="mb-0 text-white font-semibold text-xl nunito">Version 1.0</p>
        </div>
    );
}

import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import InputLayout from "./InputLayout";
import OTPInput from "otp-input-react";
import _ from "lodash";

export default function CustomInput({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    placeholder,
    col,
    inputClass,
    disabled = false,
    ...props
}) {
    return (
        <InputLayout
            col={col || 6}
            colsize={"false"}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <InputText
                className={`w-full mt-1 ${inputClass}`}
                value={value || data?.[name] || ""}
                name={name}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                placeholder={placeholder}
            />
        </InputLayout>
    );
}

export const CustomPassword = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    placeholder,
    col,
    inputClass,
    disabled = false,
    toggleMask = true,
    ...props
}) => {
    return (
        <InputLayout
            col={col || 6}
            colsize={"false"}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <Password
                className={`w-full block mt-2 ${inputClass}`}
                inputClassName="w-full"
                placeholder={placeholder}
                name={name}
                value={value || data?.[name] || ""}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                toggleMask={toggleMask}
                feedback={false}
                tabIndex={1}
            />
        </InputLayout>
    );
};
export const CustomOtpInput = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    placeholder,
    col,
    inputClass,
    disabled = false,
    ...props
}) => {
    return (
        <InputLayout
            col={col || 12}
            colsize={"false"}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <OTPInput
                autoFocus
                OTPLength={4}
                value={data?.[name] || value}
                onChange={(e) => onChange && onChange({ ...e, name, value: e })}
                otpType="number"
                className="justify-content-between "
                inputClassName="otp-box m-0 h-3rem border-400	border-1 border-round-lg"
                disabled={false}
                {...props}
            />
        </InputLayout>
    );
};

export const CheckboxComponent = ({ value, onChange, data, label, id, name }) => {
    return (
        <>
            <div className="flex align-items-center gap-1 text-left">
                <Checkbox
                    inputId={name}
                    name={name}
                    id={name}
                    checked={value || data?.[name]}
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.checked })}
                    className="checkbox-input"
                />
                <label htmlFor={id} className="ml-2 text-sm font-medium">
                    {label}
                </label>
            </div>
        </>
    );
};
export const CheckboxComponent1 = ({
    label,
    name,
    onChange,
    data,
    value,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    options,
    optionLabel,
    ...props
}) => {
    return (
        <>
            <input
                type="checkbox"
                className="checkbox-select"
                id={name}
                name={name}
                value={value}
                checked={data?.[name] == value}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
            />
        </>
    );
};
export const RadioComponent = ({ value, onChange, checked, label, id, name }) => {
    return (
        <>
            <div className="flex align-items-center">
                <RadioButton inputId={id} name={name} value={value} onChange={onChange} checked={checked} />
                <label htmlFor={id} className="ml-2">
                    {label}
                </label>
            </div>
        </>
    );
};

export const TextareaComponent = ({ value, onChange, checked, label, id, name, placeholder, cols, rows }) => {
    return (
        <>
            <InputTextarea inputId={id} name={name} value={value} className="w-full" onChange={onChange} cols={cols} rows={rows} placeholder={placeholder} />
        </>
    );
};

export const CustomNumber = ({ value, onChange, checked, label, id, name, placeholder, cols, rows }) => {
    return (
        <>
            <InputNumber inputId={id} name={name} value={value} className="w-full" onChange={onChange} cols={cols} rows={rows} placeholder={placeholder} />
        </>
    );
};

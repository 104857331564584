import React, { useEffect, useId, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutData from "./LayoutData";
import PrimaryButton from "../components/buttons/button";
import { Dialog } from "primereact/dialog";
import { logout } from "../services/auth";

export default function Sidebar({ style, extraClass, labelClass, logoSrc, logoStyle }) {
    const location = useLocation();
    const id = useId();
    const navigate = useNavigate();

    const activeLink = location?.pathname;
    const { menuItems, logOut, setLogOut } = LayoutData();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <>
            <div
                className={`flex sidebar-nav full-sidebar flex-column justify-content-between h-full overflow-y-scroll pt-3  text-center ${extraClass}`}
                style={style}
            >
                <div className="text-left">
                    <Link to="https://www.namameditation.com/" className="p-4 hidden lg:block logo-box" key={id}>
                        <img src={logoSrc} alt="" className="mb-3" style={logoStyle} />
                    </Link>

                    <ul className="list-none m-0 px-4">
                        {menuItems.map((item) => (
                            <>
                                {item?.command ? (
                                    <li key={id} className="mb-3" onClick={item.command}>
                                        <div
                                            className={`p-3 flex gap-2 border-round-xl align-items-center side_menuitem text-lg font-medium no-underline cursor-pointer ${
                                                logOut ? "bg-main text-white active " : "text-main lg:text-white"
                                            }`}
                                        >
                                            <img src={item.icon} alt="" width={item.iconWidth} className="hidden lg:block" height={item.iconHeight} />
                                            <img src={item.mobileIcon} alt="" className="block lg:hidden" width={item.iconWidth} height={item.iconHeight} />
                                            <span className={labelClass}>{item.name}</span>
                                        </div>
                                    </li>
                                ) : (
                                    <li key={id} className="mb-3">
                                        <Link
                                            to={item.link ? item.link : "/*"}
                                            target={item.target}
                                            className={`p-3 flex gap-2
                                             align-items-center border-round-xl side_menuitem text-lg font-medium no-underline ${
                                                 activeLink.includes(item.link) && !logOut ? "bg-main text-white active " : " text-main lg:text-white"
                                             }`}
                                        >
                                            <img
                                                src={isMobile && !activeLink.includes(item.link) ? item.mobileIcon : item.icon}
                                                alt=""
                                                width={item.iconWidth}
                                                height={item.iconHeight}
                                            />
                                            <span className={labelClass}>{item.name}</span>
                                        </Link>
                                    </li>
                                )}
                            </>
                        ))}
                    </ul>
                </div>
                <Dialog visible={logOut} className="header-none">
                    <p className="text-center text-lg font-semibold heading-color">Are you sure you want to Logout?</p>
                    <div className="flex justify-content-between gap-3">
                        <PrimaryButton btnclass="bg-main w-6" label="Logout" onClick={() => logout(() => navigate("/"))} />
                        <PrimaryButton btnclass="surface-300 border-300 w-6" label="Cancel" onClick={() => setLogOut(false)} />
                    </div>
                </Dialog>
            </div>
        </>
    );
}

import Membership from "../admin/membership";
import { socket } from "../socket";

export const PrivateRoutes = [
    {
        path: "/membership",
        name: "Dashboard",
        element: <Membership />,
    },
];

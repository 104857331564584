import { useState } from "react";
import MembershipIcon from "../assets/icons/membership.png";
import SubscriptionIcon from "../assets/icons/subscription.png";
import ContactIcon from "../assets/icons/contact.png";
import ProfileIcon from "../assets/icons/user-square.png";
import LogoutIcon from "../assets/icons/logout.png";
import ProfilemobileIcon from "../assets/icons/mobileuser-square.png";
import ContactMobileIcon from "../assets/icons/fi_1983019.png";
import subscriptionMobileIcon from "../assets/icons/fi_1660114.png";
import LogoutMobileIcon from "../assets/icons/fi_992680.png";

const LayoutData = () => {
    const [isFullSidebar, setIsFullSidebar] = useState(true);
    const [logOut, setLogOut] = useState(false);

    const menuItems = [
        {
            name: "Membership Plans",
            link: "/membership",
            icon: MembershipIcon,
            // mobileIcon:,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Subscription FAQ",
            link: "https://www.namameditation.com/faqs",
            icon: SubscriptionIcon,
            mobileIcon: subscriptionMobileIcon,
            iconWidth: "24px",
            iconHeight: "24px",
            target: "_blank",
        },
        {
            name: "Contact Us/Help",
            link: "https://www.namameditation.com/contact",
            icon: ContactIcon,
            mobileIcon: ContactMobileIcon,
            iconWidth: "24px",
            iconHeight: "24px",
            target: "_blank",
        },
        {
            name: "Profile",
            link: "/profile",
            icon: ProfileIcon,
            mobileIcon: ProfilemobileIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Logout",
            command: () => setLogOut(true),
            link: "/appointments",
            mobileIcon: LogoutMobileIcon,
            icon: LogoutIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
    ];
    return { menuItems, isFullSidebar, setIsFullSidebar, setLogOut, logOut };
};
export default LayoutData;

import React from "react";
import Checked from "../../assets/icons/checked.png";
import UnChecked from "../../assets/icons/not_checked.png";

export const PlanSelectcard = ({ title, plan, onClick, selectedBox, value, handleChange, data }) => {
    return (
        <div className={`plan-box mb-3 border-round-lg ${selectedBox ? "bg-main" : ""}`} onClick={onClick}>
            <div className="flex justify-content-between " onClick={(e) => handleChange({ name: "selectPlan", value })}>
                {/* <CheckboxComponent value={value === data?.["selectPlan"]} /> */}
                {/* <Checkbox checked={value === data?.["selectPlan"]} className="checkbox-select"></Checkbox> */}
                {/* <CheckboxComponent1 name={"selectPlan"} value={value} onChange={handleChange} data={data} /> */}
                <div className="w-1">{selectedBox ? <img src={Checked} alt="" width={25} /> : <img src={UnChecked} alt="" width={25} />}</div>
                <div className="flex justify-content-between gap-5 md:block" style={{ width: "80%" }}>
                    <p className={`text-base md:text-lg m-0 nunito md:mb-2 ${selectedBox ? "text-white" : "heading-color"}`}>{title}</p>
                    <h1 className={`text-base md:text-4xl font-semibold m-0 ${selectedBox ? "text-white" : "green-color"}`}>{plan}</h1>
                </div>
            </div>
        </div>
    );
};

export const LoginCard = ({ children }) => {
    return <div className="bg-white h-full p-5 text-center md:p-6 login-card">{children}</div>;
};

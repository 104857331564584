import React from "react";
import CustomDialog from "../../components/Dialog/Dialog";

const CancelDialog = ({ cancelPlan, setCancelPlan, handleCancelPlan }) => {
    return (
        <>
            <CustomDialog
                title="Cancel Membership Plan"
                contentClassName="pb-2"
                visible={cancelPlan}
                onSave={handleCancelPlan}
                onCancel={() => setCancelPlan(false)}
                label="Cancel Plan"
            >
                <p className="mt-0  text-sm font-normal text-dark-gray ">Are you sure you want to cancel your plan?</p>
            </CustomDialog>
        </>
    );
};

export default CancelDialog;

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import loaderSlice from "./slices/loaderSlice";
import toastSlice from "./slices/toastSlice";
import planSlice from "./slices/planSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        loader: loaderSlice,
        toast: toastSlice,
        plan: planSlice,
    },
});

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
import { socialLogin } from "../store/thunks/userThunk";
import { useNavigate } from "react-router-dom";

const useCustomLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            });
            const result = userInfo.data;

            console.log(result);
            dispatch(
                socialLogin(result, "google", () => {
                    navigate("/membership");
                })
            );
        },
        onError: (err) => console.log(err),
    });
    return { login };
};

export default useCustomLogin;

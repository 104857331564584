import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { getTimezone } from "../../utils/commonFunctions";
import { hideLoader, showLoader } from "../slices/loaderSlice";
import { setUserPlans } from "../slices/planSlice";
import { showToast } from "../slices/toastSlice";

const getUserPlans = () => async (dispatch) => {
    dispatch(showLoader());
    const res = await api("get", endPoints.PLANS);
    if (res.success) {
        dispatch(setUserPlans(res.data));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoader());
};
const getUserUnauthorizedPlans = () => async (dispatch) => {
    dispatch(showLoader());
    const res = await api("get", endPoints.UNPLANS);
    if (res.success) {
        dispatch(setUserPlans(res.data));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoader());
};
const getUserPaymentLink = ({ id, handleRazorpaySubscription }) => async (dispatch) => {
    dispatch(showLoader());

    let payload = {
        locale: getTimezone(),
    };
    const res = await api("post", endPoints.PAYMENT_LINK + id, payload);

    if (res.success) {
        // let paymentLink = res.data.paymentLink;
        // setSubscriptionId(res.data.subscriptionId);
        const locale = getTimezone();

        if (locale === 'US') window.location = res.data?.links[0].href;
        else handleRazorpaySubscription(res.data.subscriptionId);

        // if (newWindow) {
        //     newWindow.opener = null; // For security, disable reverse tabnabbing
        // } else {
        //     alert('Pop-up blocked! Please allow pop-ups for this site.');
        // }
        // navigate(paymentLink, { replace: true });
    } else {
        if (res?.data?.statusCode === 400) dispatch(showToast({ severity: "error", summary: res.data?.error?.description }));
        else dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoader());
    }
};
const CancelPaymentAction = (id, next) => async (dispatch) => {
    dispatch(showLoader());
    const res = await api("post", endPoints.CANCEL_RAZORPAY_PAYMENT + '/' + id, {
        // subsId: id,
        locale: getTimezone(),
    });

    if (res.success) {
        next();
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoader());
    }
};
export { getUserPlans, getUserPaymentLink, getUserUnauthorizedPlans, CancelPaymentAction };

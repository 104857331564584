import React from "react";
import { CustomBackground } from "../../components/background/CustomBackground";
import backgrooundImg from "../../assets/images/beautiful-young-woman-doing-yoga-street 1.webp";
import LogoImg from "../../assets/images/logo.png";
import PrimaryButton from "../../components/buttons/button";
import CheckSticker from "../../assets/icons/Sticker.png";
import Footer from "../../layout/footer";
import { LoginCard } from "../../components/cards/card";
import { Topbar } from "../../layout/topbar";
import { Link, useNavigate } from "react-router-dom";

export default function PasswordChanged() {
    const navigate = useNavigate();
    return (
        <>
            <Topbar />
            <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5" bgImg={backgrooundImg}>
                <LoginCard>
                    <Link to="https://www.namameditation.com/">
                        <img src={LogoImg} alt="" style={{ width: "140px" }} />
                    </Link>
                    <img src={CheckSticker} alt="" className="block m-auto mt-4 " style={{ width: "100px" }} />
                    <h1 className="md:text-center font-semibold heading-size text-main">Password Changed!</h1>
                    <p className="subheading-color text-xl nunito">Your password has been changed successfully.</p>

                    <PrimaryButton label="Back to Login" btnclass=" mt-3 bg-main w-6" onClick={() => navigate("/signin")} />
                </LoginCard>
                <Footer />
            </CustomBackground>
        </>
    );
}

import React, { useEffect, useRef } from "react";
import ProfileImg from "../assets/icons/profile.png";
import { Avatar } from "primereact/avatar";
import logoSrc from "../assets/images/logowhite.png";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction } from "../store/thunks/userThunk";
import { getImageUrl } from "../utils/commonFunctions";
import { Link } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";

export const Topbar = ({ heading, toggleSidebar, icon }) => {
    const op = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfileAction());
    }, [dispatch]);

    const { profile } = useSelector((state) => state.user);

    let username = profile?.firstName || "Loading...";
    let userimage = profile?.image;
    let useremail = profile?.email;

    return (
        <>
            <div className={"p-3 top-header z-3 flex align-items-center justify-content-between p-3 md:px-5 topbar-container border-bottom-1 border-200"}>
                <div className="flex gap-3 align-items-center">
                    <div className="toggle-menu lg:hidden cursor-pointer" onClick={() => toggleSidebar((prev) => !prev)}>
                        <i className={`text-2xl text-white font-semibold text-main ${icon}`}></i>
                    </div>
                    <Link to="https://www.namameditation.com/" className="p-1 block lg:hidden logo-box">
                        <img src={logoSrc} alt="" style={{ width: "110px" }} />
                    </Link>
                    <h3 className="hidden lg:block top-header-heading m-0 text-dark font-semibold text-2xl">{heading}</h3>
                </div>
                <div className="h-full flex align-items-center justify-content-between">
                    <div className="flex justify-content-between align-items-center ml-3">
                        <div className="flex align-items-center">
                            {userimage ? (
                                <Avatar image={getImageUrl(userimage)} shape="circle" />
                            ) : (
                                <Avatar image={ProfileImg} className="bg-transparent overflow-hidden" size="large" shape="circle" />
                            )}

                            <div className="text-left ml-2">
                                <p className="text-sm heading-color font-semibold mt-0 mb-1">{username}</p>
                                <p className="text-xs md:text-sm subheading-color m-0 nunito hidden md:block">{useremail}</p>
                            </div>
                            <i className="pi pi-ellipsis-v text-xs block md:hidden cursor-pointer" onClick={(e) => op.current.toggle(e)}></i>
                            <OverlayPanel className="email-popup" ref={op}>
                                <p className="text-xs md:text-sm text-black m-0 nunito">{useremail}</p>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

import React from "react";
import Sidebar from "./sidebar";
import { Topbar } from "./topbar";
import LayoutData from "./LayoutData";
import LogoMain from "../assets/images/logowhite.png";

export default function DashboardLayout({ children, heading, label, onClick }) {
  const { isFullSidebar, setIsFullSidebar } = LayoutData();

  return (
    <div>
      <div className="flex justify-content-end m-0 relative top-0 ">
        <div
          className={`sidebar-container fixed left-0 p-0  ${
            isFullSidebar ? "mobile-hide-menu" : "mobile-show-menu"
          }`}
          style={{ width: "300px", height: "100vh" }}
        >
          <Sidebar
            logoSrc={LogoMain}
            logoStyle={{ width: "120px" }}
            style={{
              width: "300px",
            }}
          />
        </div>

        <div
          className="main-content p-0  bg-dashboard"
          style={{
            width: "calc(100% - 300px)",
            height: "100vh",
          }}
        >
          <Topbar
            isFullSidebar={isFullSidebar}
            toggleSidebar={setIsFullSidebar}
            icon={isFullSidebar ? "pi pi-bars" : "pi pi-times"}
            heading={heading}
            label={label}
            onClick={onClick}
          />
          <div className="py-4 md:py-6 px-4 md:px-6 overflow-auto dashboard-detail-container">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

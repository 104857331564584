import React, { useEffect, useState } from "react";
import { CustomBackground } from "../../components/background/CustomBackground";
import backgrooundImg from "../../assets/images/login-bg.jpg";
import LogoImg from "../../assets/images/logo.png";
import CustomInput, { CheckboxComponent, CustomPassword } from "../../components/input/allinput";
import PrimaryButton, { SocialButton } from "../../components/buttons/button";
import GoogleIcon from "../../assets/icons/Google logo.png";
import IOSIcon from "../../assets/icons/apple.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../layout/footer";
import { LoginCard } from "../../components/cards/card";
import { Topbar } from "../../layout/topbar";
import formValidation from "../../utils/validation";
import { showFormErrors } from "../../utils/commonFunctions";
import { socialLogin, userLoginAction } from "../../store/thunks/userThunk";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import AppleLogin from "react-apple-login";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import useCustomLogin from "../../lib/useCustomLogin";
import { isAuthenticated } from "../../services/auth";

export default function SignIn() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        email: "",
        password: "",
        rememberMe: true,
    });

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSignIN = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                userLoginAction(data, () => {
                    navigate("/membership");
                })
            );
        }
    };
    useEffect(() => {
        if (isAuthenticated()) {
            navigate("/membership");
        }
    }, [navigate]);
    const { login } = useCustomLogin();

    return (
        <>
            <Topbar />
            <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5 login-bg" bgImg={backgrooundImg}>
                <LoginCard>
                    <Link to="https://www.namameditation.com/">
                        <img src={LogoImg} alt="" style={{ width: "140px" }} />
                    </Link>
                    <h1 className="md:text-center font-semibold heading-size text-main">Welcome Back!</h1>
                    <p className="subheading-color text-xl nunito">Sign in to your account</p>
                    <CustomInput label="Email Address" extraClassName="w-full p-0" placeholder="" name="email" onChange={handleChange} data={data} />
                    <CustomPassword label="Password" extraClassName="w-full p-0" name="password" onChange={handleChange} data={data} />
                    <div className="flex justify-content-between">
                        <CheckboxComponent label="Remember me" onChange={handleChange} data={data} name="rememberMe" />
                        <Link to="/forgot-password" className="heading-color no-underline font-medium">
                            Forgot password?
                        </Link>
                    </div>
                    <PrimaryButton label="Sign In" btnclass=" mt-3 bg-main w-full" onClick={handleSignIN} />
                    <div className="signup-divider mt-3 relative text-center">
                        <span className="text-sm heading-color font-medium">Or Sign In with</span>
                    </div>
                    <div className="flex justify-content-center gap-4">
                        {/* <AppleLogin
                            clientId="com.app.NamaMeditation"
                            redirectURI="https://localhost:3000/membership"
                            usePopup={true}
                            responseType={"code"}
                            responseMode={"query"}
                            designProp={{
                                height: 30,
                                width: 140,
                                color: "black",
                                border: false,
                                type: "sign-in",
                                border_radius: 15,
                                scale: 1,
                                locale: "en_US",
                            }}
                            // onSuccess={handleAppleResponse}
                            // onError={handleAppleError}
                        /> */}
                        <Button
                            className={`social-btn bg-transparent border-200 p-3 border-round-xl px-6 text-black mt-3 justify-content-center gap-3`}
                            onClick={() => login()}
                        >
                            <img src={GoogleIcon} alt="" style={{ width: "20px" }} />
                        </Button>
                        <Button className={`social-btn bg-transparent border-200 p-3 border-round-xl px-6 text-black mt-3 justify-content-center gap-3`}>
                            <img src={IOSIcon} alt="" style={{ width: "20px" }} />
                        </Button>
                    </div>
                    <p>
                        Don’t have an account? &nbsp;
                        <Link to="/" className="heading-color font-semibold no-underline">
                            Sign up
                        </Link>
                    </p>
                </LoginCard>
                <Footer />
            </CustomBackground>
        </>
    );
}

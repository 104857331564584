import constants from "../../constants";
import api from "../../services/api";
import { authenticate } from "../../services/auth";
import endPoints from "../../services/endPoints";
import { hideLoader, showLoader } from "../slices/loaderSlice";
import { showToast } from "../slices/toastSlice";
import { setUserProfile } from "../slices/userSlice";
import { getUserPaymentLink } from "./planThunk";

const userLoginAction = (data, next) => async (dispatch) => {
    let payload = { email: data.email, password: data.password };
    dispatch(showLoader());
    const res = await api("post", endPoints.LOGIN, payload);
    if (res.success) {
        let user = {
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
        };
        let token = res.data.token;
        authenticate(token, data.rememberMe, () => {
            dispatch(setUserProfile(user));
            next();
        });
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoader());
};
const userRegisterAction = (data, next) => async (dispatch) => {
    dispatch(showLoader());
    const payload = {
        otpCode: data.otp,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        socialId: "",
    };
    const res = await api("post", endPoints.REGISTER, payload);
    if (res.success) {
        let user = {
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
        };
        let token = res.data.token;
        authenticate(token, true, () => {
            dispatch(setUserProfile(user));
            if (data.selectPlan) {
                dispatch(getUserPaymentLink({ id: data.selectPlan, handleRazorpaySubscription: next }));
            } else {
                next();
            }
        });
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoader());
};
const RequestOTPAction = (email, next) => async (dispatch) => {
    dispatch(showLoader());

    const res = await api("post", endPoints.REQUEST_OTP, { email });
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
        next();
    } else {
        dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
    }
    dispatch(hideLoader());
};
const getProfileAction = () => async (dispatch) => {
    const res = await api("get", endPoints.PROFILE);
    if (res.success) {
        dispatch(setUserProfile(res.data));
    }
};
const sendEmailAction = (email, next) => async (dispatch) => {
    dispatch(showLoader());
    const res = await api("post", endPoints.SENT_OTP, { email });
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
        next();
    } else {
        dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
    }
    dispatch(hideLoader());
};
const forgotPasswordAction = (otp, email, password, next) => async (dispatch) => {
    dispatch(showLoader());
    const payload = {
        otpCode: otp,
        email: email,
        password: password,
    };
    const res = await api("post", endPoints.RESET_FORGOT_PASSWORD, payload);
    if (res.success) {
        next();
    } else {
        dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
    }
    dispatch(hideLoader());
};

const socialLogin = (data, type, next) => async (dispatch) => {
    const payload = {
        email: data.email,
        socialId: data.sub,
        loginType: type,
        firstName: data.given_name,
        lastName: data.family_name,
    };

    const res = await api("post", endPoints.SOCIAL_LOGIN, payload);
    if (res.success) {
        if (res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));

            authenticate(res.data.token, true, () => {
                next();
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
export { userLoginAction, RequestOTPAction, userRegisterAction, getProfileAction, sendEmailAction, forgotPasswordAction, socialLogin };

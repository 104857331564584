import React, { useState } from "react";
import { CustomBackground } from "../../components/background/CustomBackground";
import backgrooundImg from "../../assets/images/register-bg.jpg";
import LogoImg from "../../assets/images/logo.png";
import CustomInput, { CheckboxComponent, CustomPassword } from "../../components/input/allinput";
import PrimaryButton, { SocialButton } from "../../components/buttons/button";
import GoogleIcon from "../../assets/icons/Google logo.png";
import IOSIcon from "../../assets/icons/apple.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../layout/footer";
import { LoginCard } from "../../components/cards/card";
import { Topbar } from "../../layout/topbar";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { RequestOTPAction } from "../../store/thunks/userThunk";
import useCustomLogin from "../../lib/useCustomLogin";
import { Button } from "primereact/button";

export default function Signup({ setSelectPlan, setVerifyOtp, setSignUp, handleChange, data, setData }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CheckboxTemplate = () => {
        return (
            <>
                I agree to the{" "}
                <Link to="https://www.namameditation.com/eula" target="_blank" className="text-main no-underline font-medium">
                    Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="https://www.namameditation.com/privacypolicy" target="_blank" className="text-main no-underline font-medium">
                    Privacy Policy
                </Link>
                <span className="text-red-600">*</span>
            </>
        );
    };

    const handleSignUp = () => {
        if (showFormErrors(data, setData, ["otp"])) {
            console.log("hi");
            dispatch(
                RequestOTPAction(data?.email, () => {
                    setVerifyOtp(true);
                    setSignUp(false);
                    setSelectPlan(false);
                })
            );
        }
    };
    const { login } = useCustomLogin();

    return (
        <>
            <Topbar />
            <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5" bgImg={backgrooundImg}>
                <LoginCard>
                    <Link to="https://www.namameditation.com/">
                        <img src={LogoImg} alt="" style={{ width: "140px" }} />
                    </Link>
                    <h1 className="md:text-center font-semibold heading-size text-main ">Sign Up</h1>
                    <p className="subheading-color text-xl nunito">Hello! Register to get started.</p>
                    <CustomInput label="First Name" name="firstName" extraClassName="w-full p-0" onChange={handleChange} data={data} />
                    <CustomInput label="Email Address" extraClassName="w-full p-0" name="email" onChange={handleChange} data={data} />
                    <CustomPassword label="Password" extraClassName="w-full p-0" data={data} name="password" onChange={handleChange} />
                    <CustomPassword label="Confirm Password" extraClassName="w-full p-0" data={data} name="confirmPassword" onChange={handleChange} />
                    <CheckboxComponent label={CheckboxTemplate()} data={data} name="isChecked" onChange={handleChange} />
                    <PrimaryButton
                        label="Sign Up"
                        btnclass={` mt-3 ${data?.isChecked ? "bg-main" : "bg-gray"} border-300 w-full`}
                        onClick={handleSignUp}
                        disable={data?.isChecked ? false : true}
                    />
                    <div className="signup-divider mt-3 relative text-center">
                        <span className="text-sm heading-color font-medium">or continue with</span>
                    </div>
                    <div className="flex justify-content-center gap-4">
                        <Button
                            className={`social-btn bg-transparent border-200 p-3 border-round-xl px-6 text-black mt-3 justify-content-center gap-3`}
                            onClick={() => login()}
                        >
                            <img src={GoogleIcon} alt="" style={{ width: "20px" }} />
                        </Button>
                        <SocialButton src={IOSIcon} btnclass="mt-3 justify-content-center gap-3" />
                    </div>
                    <div className="cursor-pointer" onClick={() => navigate("/signin")}>
                        Already have an account? <div className="heading-color font-semibold no-underline">Sign In</div>
                    </div>
                </LoginCard>
                <Footer />
            </CustomBackground>
        </>
    );
}

import React from "react";
import { Button } from "primereact/button";

export default function PrimaryButton({ type, onClick, label, loading, className, btnclass, disable, icon, outlined, ...props }) {
    return (
        <Button
            type={type}
            label={label}
            icon={icon}
            onClick={onClick}
            loading={loading}
            disabled={disable}
            outlined={outlined}
            className={`text-sm nunito border-round-xl main-btn hover:shadow-5 font-normal ${btnclass}`}
            {...props}
        />
    );
}

export const SocialButton = ({ type, onClick, src, label, loading, className, btnclass, disable, ...props }) => {
    return (
        <>
            <Button
                type={type}
                onClick={onClick}
                loading={loading}
                disabled={disable}
                className={`social-btn bg-transparent border-200 p-3 border-round-xl px-6 text-black ${btnclass}`}
                {...props}
            >
                <img src={src} alt="" style={{ width: "20px" }} /> {label}
            </Button>
        </>
    );
};

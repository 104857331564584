import React, { useState } from "react";
import { CustomBackground } from "../../components/background/CustomBackground";
import backgrooundImg from "../../assets/images/beautiful-young-woman-doing-yoga-street 1.webp";
import LogoImg from "../../assets/images/logo.png";
import { CustomPassword } from "../../components/input/allinput";
import PrimaryButton from "../../components/buttons/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../layout/footer";
import { LoginCard } from "../../components/cards/card";
import { Topbar } from "../../layout/topbar";
import formValidation from "../../utils/validation";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { forgotPasswordAction } from "../../store/thunks/userThunk";
var base64 = require("base-64");

export default function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        password: "",
        confirmPassword: "",
    });
    const { encodedEmail, otpCode } = useParams();
    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const email = base64.decode(encodedEmail);
    const otp = base64.decode(otpCode);

    console.log(otp, otpCode, email, "otp");

    const handleResetPassword = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                forgotPasswordAction(otp, email, data.password, () => {
                    navigate("/password-changed");
                })
            );
        }
    };
    return (
        <>
            <Topbar />
            <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5" bgImg={backgrooundImg}>
                <LoginCard>
                    <Link to="https://www.namameditation.com/">
                        <img src={LogoImg} alt="" style={{ width: "140px" }} />
                    </Link>
                    <h1 className="md:text-center font-semibold heading-size text-main">Reset Password</h1>
                    <p className="subheading-color text-xl nunito">Your new password must be unique from those previously used.</p>
                    <CustomPassword label="New Password" extraClassName="w-full p-0" name="password" onChange={handleChange} data={data} />
                    <CustomPassword label="Confirm Password" extraClassName="w-full p-0" name="confirmPassword" data={data} onChange={handleChange} />
                    <PrimaryButton label="Reset Password" btnclass=" mt-3 bg-main w-full" onClick={handleResetPassword} />
                </LoginCard>
                <Footer />
            </CustomBackground>
        </>
    );
}
